import React, { Component } from "react";
import { Link } from "react-router-dom";
import { withTranslate } from "react-redux-multilingual";

class NavBar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      navClose: { right: "0px" },
    };
  }

  componentWillMount() {
    if (window.innerWidth < 750) {
      this.setState({ navClose: { right: "-410px" } });
    }
    if (window.innerWidth < 1199) {
      this.setState({ navClose: { right: "-300px" } });
    }
  }

  openNav() {
    console.log("open");
    this.setState({ navClose: { right: "0px" } });
  }
  closeNav() {
    this.setState({ navClose: { right: "-410px" } });
  }

  onMouseEnterHandler() {
    if (window.innerWidth > 1199) {
      document.querySelector("#main-menu").classList.add("hover-unset");
    }
  }

  handleSubmenu = (event) => {
    if (event.target.classList.contains("sub-arrow")) return;

    if (event.target.nextElementSibling.classList.contains("opensubmenu")) event.target.nextElementSibling.classList.remove("opensubmenu");
    else {
      document.querySelectorAll(".nav-submenu").forEach(function(value) {
        value.classList.remove("opensubmenu");
      });
      document.querySelector(".mega-menu-container").classList.remove("opensubmenu");
      event.target.nextElementSibling.classList.add("opensubmenu");
    }
  };

  handleMegaSubmenu = (event) => {
    if (event.target.classList.contains("sub-arrow")) return;

    if (event.target.parentNode.nextElementSibling.classList.contains("opensubmegamenu")) event.target.parentNode.nextElementSibling.classList.remove("opensubmegamenu");
    else {
      document.querySelectorAll(".menu-content").forEach(function(value) {
        value.classList.remove("opensubmegamenu");
      });
      event.target.parentNode.nextElementSibling.classList.add("opensubmegamenu");
    }
  };

  render() {
    const { translate } = this.props;
    return (
      <div>
        <div className="main-navbar">
          <div id="mainnav">
            <div className="toggle-nav" onClick={this.openNav.bind(this)}>
              <i className="fa fa-bars sidebar-bar"></i>
            </div>
            <ul className="nav-menu" style={this.state.navClose}>
              <li className="back-btn" onClick={this.closeNav.bind(this)}>
                <div className="mobile-back text-right">
                  <span>Back</span>
                  <i className="fa fa-angle-right pl-2" aria-hidden="true"></i>
                </div>
              </li>
              <li>
                <Link to={`${process.env.PUBLIC_URL}/`}>
                  {translate("home")}
                  {/* <span className="sub-arrow"></span> */}
                </Link>
                {/* <ul className="nav-submenu" >
                                    <li><Link to={`${process.env.PUBLIC_URL}/fashion`} >{translate('fashion')}</Link></li>
                                    <li><Link to={`${process.env.PUBLIC_URL}/beauty`} >{translate('beauty')}</Link></li>
                                    <li><Link to={`${process.env.PUBLIC_URL}/electronic`} >{translate('electronic')}</Link></li>
                                    <li><Link to={`${process.env.PUBLIC_URL}/furniture`} >{translate('furniture')}</Link></li>
                                    <li><Link to={`${process.env.PUBLIC_URL}/kids`} >{translate('kids')}</Link></li>
                                    <li><Link to={`${process.env.PUBLIC_URL}/pets`} >{translate('pets')}</Link></li>
                                    <li><Link to={`${process.env.PUBLIC_URL}/vegetables`} >{translate('vegetables')}</Link></li>
                                    <li><Link to={`${process.env.PUBLIC_URL}/watch`} >{translate('watch')}</Link></li>
                                </ul> */}
              </li>
              {/* <li >
                                <Link to="#" className="nav-link" onClick={(e) => this.handleSubmenu(e)}>
                                    {translate('shop')}
                                    <span className="sub-arrow"></span>
                                </Link>
                                <ul className="nav-submenu">
                                    <li><Link to={`${process.env.PUBLIC_URL}/left-sidebar/collection`} >{translate('category_left_sidebar')}</Link></li>
                                    <li><Link to={`${process.env.PUBLIC_URL}/right-sidebar/collection`} >{translate('category_right_sidebar')}</Link></li>
                                    <li><Link to={`${process.env.PUBLIC_URL}/no-sidebar/collection`} >{translate('category_no_sidebar')}</Link></li>
                                    <li><Link to={`${process.env.PUBLIC_URL}/metro/collection`} >{translate('category_metro')}</Link></li>
                                    <li><Link to={`${process.env.PUBLIC_URL}/full-width/collection`} >{translate('category_full_width')}</Link></li>
                                </ul>
                            </li>
                            <li >
                                <Link to="#" className="nav-link" onClick={(e) => this.handleSubmenu(e)}>
                                    {translate('products')}
                                    <span className="sub-arrow"></span>
                                </Link>
                                <ul className="nav-submenu">
                                    <li><Link to={`${process.env.PUBLIC_URL}/left-sidebar/product/1`} >{translate('left_sidebar')}</Link></li>
                                    <li><Link to={`${process.env.PUBLIC_URL}/right-sidebar/product/1`} >{translate('right_sidebar')}</Link></li>
                                    <li><Link to={`${process.env.PUBLIC_URL}/no-sidebar/product/1`} >{translate('no_sidebar')}</Link></li>
                                    <li><Link to={`${process.env.PUBLIC_URL}/col-left/product/1`} >{translate('three_col_thumbnail_left')}</Link></li>
                                    <li><Link to={`${process.env.PUBLIC_URL}/col-right/product/1`} >{translate('three_col_thumbnail_right')}</Link></li>
                                    <li><Link to={`${process.env.PUBLIC_URL}/column/product/1`} >{translate('thumbnail_below')}</Link></li>
                                    <li><Link to={`${process.env.PUBLIC_URL}/left-image/product/1`} >{translate('thumbnail_left')}</Link></li>
                                    <li><Link to={`${process.env.PUBLIC_URL}/right-image/product/1`} >{translate('thumbnail_right')}</Link></li>
                                </ul>
                            </li> */}
              <li className="mega-menu">
                <Link
                  to="#"
                  // {{
                  //   pathname: `${process.env.PUBLIC_URL}/products`,
                  //   state: { category: "all", itemType: "all" },
                  // }}
                  className="dropdown"
                  onClick={(e) => this.handleSubmenu(e)}
                >
                  {translate("products")}
                  <span className="sub-arrow"></span>
                </Link>
                <div className="mega-menu-container">
                  <div className="container">
                    <div className="row">
                      <div className="col mega-box">
                        <div className="link-section">
                          <div className="menu-title">
                            <h5 onClick={(e) => this.handleMegaSubmenu(e)}>
                              {translate("dog")}
                              <span className="sub-arrow"></span>
                            </h5>
                            {/* <h5>
                                                        <Link to={
                                                            { pathname: `${process.env.PUBLIC_URL}/products`,
                                                        // state: {
                                                        //     category:'dog',
                                                        //     itemType:null
                                                        // }
                                                        }} >{translate('dog')}</Link>
                                                        </h5> */}
                          </div>
                          <div className="menu-content">
                            <ul>
                              <li>
                                <Link
                                  to={{
                                    pathname: `${process.env.PUBLIC_URL}/products`,
                                    state: {
                                      category: "dog",
                                      itemType: "treat",
                                    },
                                  }}
                                >
                                  {translate("treat")}
                                </Link>
                              </li>
                              <li>
                                <Link
                                  to={{
                                    pathname: `${process.env.PUBLIC_URL}/products`,
                                    state: { category: "dog", itemType: "toy" },
                                  }}
                                >
                                  {translate("toy")}
                                </Link>
                              </li>
                              <li>
                                <Link
                                  to={{
                                    pathname: `${process.env.PUBLIC_URL}/products`,
                                    state: {
                                      category: "dog",
                                      itemType: "grooming_and_cleaning",
                                    },
                                  }}
                                >
                                  {translate("grooming_and_cleaning")}
                                </Link>
                              </li>
                              <li>
                                <Link
                                  to={{
                                    pathname: `${process.env.PUBLIC_URL}/products`,
                                    state: {
                                      category: "dog",
                                      itemType: "health_and_pharmaceuticals",
                                    },
                                  }}
                                >
                                  {translate("health_and_pharmaceuticals")}
                                </Link>
                              </li>
                              <li>
                                <Link
                                  to={{
                                    pathname: `${process.env.PUBLIC_URL}/products`,
                                    state: {
                                      category: "dog",
                                      itemType: "feeders_and_drinkers",
                                    },
                                  }}
                                >
                                  {translate("feeders_and_drinkers")}
                                </Link>
                              </li>
                              <li>
                                <Link
                                  to={{
                                    pathname: `${process.env.PUBLIC_URL}/products`,
                                    state: {
                                      category: "dog",
                                      itemType: "clean_up",
                                    },
                                  }}
                                >
                                  {translate("clean_up")}
                                </Link>
                              </li>
                              <li>
                                <Link
                                  to={{
                                    pathname: `${process.env.PUBLIC_URL}/products`,
                                    state: {
                                      category: "dog",
                                      itemType: "collars_and_leads",
                                    },
                                  }}
                                >
                                  {translate("collars_and_leads")}
                                </Link>
                              </li>
                              <li>
                                <Link
                                  to={{
                                    pathname: `${process.env.PUBLIC_URL}/products`,
                                    state: {
                                      category: "dog",
                                      itemType: "bedding",
                                    },
                                  }}
                                >
                                  {translate("bedding")}
                                </Link>
                              </li>
                              {/* <li><Link to={`${process.env.PUBLIC_URL}/features/portfolio-grid/3`} >{translate('portfolio_grid_3')}</Link></li> */}
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="col mega-box">
                        <div className="link-section">
                          <div className="menu-title">
                            <h5 onClick={(e) => this.handleMegaSubmenu(e)}>
                              {translate("cat")}
                              <span className="sub-arrow"></span>
                            </h5>
                            {/* <h5>
                                                        <Link to={`${process.env.PUBLIC_URL}/features/portfolio-grid/4`} >{translate('cat')}</Link>
                                                        </h5> */}
                          </div>
                          <div className="menu-content">
                            <ul>
                              <li>
                                <Link
                                  to={{
                                    pathname: `${process.env.PUBLIC_URL}/products`,
                                    state: { category: "cat", itemType: "toy" },
                                  }}
                                >
                                  {translate("toy")}
                                </Link>
                              </li>
                              <li>
                                <Link
                                  to={{
                                    pathname: `${process.env.PUBLIC_URL}/products`,
                                    state: {
                                      category: "cat",
                                      itemType: "grooming_and_cleaning",
                                    },
                                  }}
                                >
                                  {translate("grooming_and_cleaning")}
                                </Link>
                              </li>
                              <li>
                                <Link
                                  to={{
                                    pathname: `${process.env.PUBLIC_URL}/products`,
                                    state: {
                                      category: "cat",
                                      itemType: "health_and_pharmaceuticals",
                                    },
                                  }}
                                >
                                  {translate("health_and_pharmaceuticals")}
                                </Link>
                              </li>
                              <li>
                                <Link
                                  to={{
                                    pathname: `${process.env.PUBLIC_URL}/products`,
                                    state: {
                                      category: "cat",
                                      itemType: "feeders_and_drinkers",
                                    },
                                  }}
                                >
                                  {translate("feeders_and_drinkers")}
                                </Link>
                              </li>
                              <li>
                                <Link
                                  to={{
                                    pathname: `${process.env.PUBLIC_URL}/products`,
                                    state: {
                                      category: "cat",
                                      itemType: "litter_or_trays",
                                    },
                                  }}
                                >
                                  {translate("litter_or_trays")}
                                </Link>
                              </li>
                              <li>
                                <Link
                                  to={{
                                    pathname: `${process.env.PUBLIC_URL}/products`,
                                    state: {
                                      category: "cat",
                                      itemType: "clean_up",
                                    },
                                  }}
                                >
                                  {translate("clean_up")}
                                </Link>
                              </li>
                              <li>
                                <Link
                                  to={{
                                    pathname: `${process.env.PUBLIC_URL}/products`,
                                    state: {
                                      category: "cat",
                                      itemType: "collars_and_leads",
                                    },
                                  }}
                                >
                                  {translate("collars_and_leads")}
                                </Link>
                              </li>

                              {/*<li><Link to={`${process.env.PUBLIC_URL}/features/element-ratio`} >{translate('image_size_ratio')}</Link></li>*/}
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="col mega-box">
                        <div className="link-section">
                          <div className="menu-title">
                            <h5 onClick={(e) => this.handleMegaSubmenu(e)}>
                              {translate("fish")}
                              <span className="sub-arrow"></span>
                            </h5>
                            {/* <h5>
                                                        <Link to={`${process.env.PUBLIC_URL}/features/portfolio-grid/4`} >{translate('fish')}</Link>
                                                        </h5> */}
                          </div>
                          <div className="menu-content">
                            <ul>
                              {/* <li className="up-text"><Link to={`${process.env.PUBLIC_URL}/features/element-product-box`} >{translate('product_box')}<span>10+</span></Link></li> */}
                              <li>
                                <Link
                                  to={{
                                    pathname: `${process.env.PUBLIC_URL}/products`,
                                    state: {
                                      category: "fish",
                                      itemType: "food",
                                    },
                                  }}
                                >
                                  {translate("food")}
                                </Link>
                              </li>
                              <li>
                                <Link
                                  to={{
                                    pathname: `${process.env.PUBLIC_URL}/products`,
                                    state: {
                                      category: "fish",
                                      itemType: "food_and_supplements",
                                    },
                                  }}
                                >
                                  {translate("food_and_supplements")}
                                </Link>
                              </li>
                              <li>
                                <Link
                                  to={{
                                    pathname: `${process.env.PUBLIC_URL}/products`,
                                    state: {
                                      category: "fish",
                                      itemType: "medications_and_treatments",
                                    },
                                  }}
                                >
                                  {translate("medications_and_treatments")}
                                </Link>
                              </li>
                              <li>
                                <Link
                                  to={{
                                    pathname: `${process.env.PUBLIC_URL}/products`,
                                    state: {
                                      category: "fish",
                                      itemType: "gravel",
                                    },
                                  }}
                                >
                                  {translate("gravel")}
                                </Link>
                              </li>
                              <li>
                                <Link
                                  to={{
                                    pathname: `${process.env.PUBLIC_URL}/products`,
                                    state: {
                                      category: "fish",
                                      itemType: "accessories",
                                    },
                                  }}
                                >
                                  {translate("accessories")}
                                </Link>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="col mega-box">
                        <div className="link-section">
                          <div className="menu-title">
                            <h5 onClick={(e) => this.handleMegaSubmenu(e)}>
                              {translate("bird")}
                              <span className="sub-arrow"></span>
                            </h5>
                            {/* <h5>
                                                        <Link to={`${process.env.PUBLIC_URL}/features/portfolio-grid/4`} >{translate('bird')}</Link>
                                                        </h5> */}
                          </div>
                          <div className="menu-content">
                            <ul>
                              <li>
                                <Link
                                  to={{
                                    pathname: `${process.env.PUBLIC_URL}/products`,
                                    state: {
                                      category: "bird",
                                      itemType: "food",
                                    },
                                  }}
                                >
                                  {translate("food")}
                                </Link>
                              </li>
                              <li>
                                <Link
                                  to={{
                                    pathname: `${process.env.PUBLIC_URL}/products`,
                                    state: {
                                      category: "bird",
                                      itemType: "toy",
                                    },
                                  }}
                                >
                                  {translate("toy")}
                                </Link>
                              </li>
                              <li>
                                <Link
                                  to={{
                                    pathname: `${process.env.PUBLIC_URL}/products`,
                                    state: {
                                      category: "bird",
                                      itemType: "feeders_and_drinkers",
                                    },
                                  }}
                                >
                                  {translate("feeders_and_drinkers")}
                                </Link>
                              </li>
                              <li>
                                <Link
                                  to={{
                                    pathname: `${process.env.PUBLIC_URL}/products`,
                                    state: {
                                      category: "bird",
                                      itemType: "accessories",
                                    },
                                  }}
                                >
                                  {translate("accessories")}
                                </Link>
                              </li>
                              <li>
                                <Link
                                  to={{
                                    pathname: `${process.env.PUBLIC_URL}/products`,
                                    state: {
                                      category: "bird",
                                      itemType: "nest_box",
                                    },
                                  }}
                                >
                                  {translate("nest_box")}
                                </Link>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="col mega-box">
                        <div className="link-section">
                          <div className="menu-title">
                            <h5 onClick={(e) => this.handleMegaSubmenu(e)}>
                              {translate("other")}
                              <span className="sub-arrow"></span>
                            </h5>
                            {/* <h5>
                                                        <Link to={`${process.env.PUBLIC_URL}/features/portfolio-grid/4`} >{translate('other')}</Link>
                                                        </h5> */}
                          </div>
                          <div className="menu-content">
                            <ul>
                              <li>
                                <Link
                                  to={{
                                    pathname: `${process.env.PUBLIC_URL}/products`,
                                    state: {
                                      category: "other",
                                      itemType: "food",
                                    },
                                  }}
                                >
                                  {translate("food")}
                                </Link>
                              </li>
                              <li>
                                <Link
                                  to={{
                                    pathname: `${process.env.PUBLIC_URL}/products`,
                                    state: {
                                      category: "other",
                                      itemType: "feeders_and_drinkers",
                                    },
                                  }}
                                >
                                  {translate("feeders_and_drinkers")}
                                </Link>
                              </li>
                              <li>
                                <Link
                                  to={{
                                    pathname: `${process.env.PUBLIC_URL}/products`,
                                    state: {
                                      category: "other",
                                      itemType: "bedding",
                                    },
                                  }}
                                >
                                  {translate("bedding")}
                                </Link>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      {/* <div className="col mega-box">
                                                <div className="link-section">
                                                    <div className="menu-title" >
                                                        <h5 onClick={(e) => this.handleMegaSubmenu(e)}>
                                                            {translate('men_accessories')}
                                                            <span className="sub-arrow"></span>
                                                        </h5>
                                                    </div>
                                                    <div className="menu-content" >
                                                        <ul>
                                                            <li><a href="#">{translate('ties')}</a></li>
                                                            <li><a href="#">{translate('cufflinks')}</a></li>
                                                            <li><a href="#">{translate('pockets_squares')}</a></li>
                                                            <li><a href="#">{translate('helmets')}</a></li>
                                                            <li><a href="#">{translate('scarves')}</a></li>
                                                            <li><a href="#">{translate('phone_cases')}</a></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div> */}
                    </div>
                  </div>
                </div>
              </li>
              {/* <li>
                                <Link to="#" className="nav-link" onClick={(e) => this.handleSubmenu(e)}>
                                    {translate('pages')}
                                    <span className="sub-arrow"></span>
                                </Link>
                                <ul className="nav-submenu">
                                    <li><Link to={`${process.env.PUBLIC_URL}/pages/about-us`} >{translate('about_us')}</Link></li>
                                    <li><Link to={`${process.env.PUBLIC_URL}/pages/404`} >404</Link></li>
                                    <li><Link to={`${process.env.PUBLIC_URL}/pages/lookbook`} >{translate('lookbook')}</Link></li>
                                    <li><Link to={`${process.env.PUBLIC_URL}/pages/login`} >{translate('login')}</Link></li>
                                    <li><Link to={`${process.env.PUBLIC_URL}/pages/register`} >{translate('register')}</Link></li>
                                    <li><Link to={`${process.env.PUBLIC_URL}/pages/search`} >{translate('search')}</Link></li>
                                    <li><Link to={`${process.env.PUBLIC_URL}/pages/collection`} >{translate('collection')}</Link></li>
                                    <li><Link to={`${process.env.PUBLIC_URL}/pages/forget-password`} >{translate('forget_password')}</Link></li>
                                    <li><Link to={`${process.env.PUBLIC_URL}/pages/contact`} >{translate('contact')}</Link></li>
                                    <li><Link to={`${process.env.PUBLIC_URL}/pages/dashboard`} >{translate('dashboard')}</Link></li>
                                    <li><Link to={`${process.env.PUBLIC_URL}/pages/faq`} >{translate('FAQ')}</Link></li>
                                </ul>
                            </li> */}
              {/* <li>
                                <Link to="#" className="nav-link" onClick={(e) => this.handleSubmenu(e)}>
                                    {translate('blog')}
                                    <span className="sub-arrow"></span>
                                </Link>
                                <ul className="nav-submenu">
                                    <li><Link to={`${process.env.PUBLIC_URL}/blog/blog-page`} >{translate('blog_left_sidebar')}</Link></li>
                                    <li><Link to={`${process.env.PUBLIC_URL}/blog/right-sidebar`} >{translate('blog_right_sidebar')}</Link></li>
                                    <li><Link to={`${process.env.PUBLIC_URL}/blog/details`} >{translate('blog_detail')}</Link></li>
                                </ul>
                            </li> */}
              <li>
                <Link to={`${process.env.PUBLIC_URL}/contact-us`}>{translate("contact_us")}</Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslate(NavBar);
