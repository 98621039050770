import React, { Component } from "react";
import firebase from "firebase";
import Breadcrumb from "../common/breadcrumb";

class Contact extends Component {
  state = {
    info: {
      firstName: "",
      lastName: "",
      phoneNumber: "",
      email: "",
      message: "",
    },
    sent: false,
  };

  constructor(props) {
    super(props);
    this.handleSent = this.handleSent.bind(this);
  }

  componentDidMount() {
    this.setState({
      info: {
        firstName: "",
        lastName: "",
        phoneNumber: "",
        email: "",
        message: "",
      },
      sent: false,
    });
  }

  handleChangeFirstName = (e) => {
    this.setState({
      info: {
        ...this.state.info,
        firstName: e.target.value,
      },
    });
  };
  handleChangeLastName = (e) => {
    this.setState({
      info: {
        ...this.state.info,
        lastName: e.target.value,
      },
    });
  };
  handleChangePhoneNumber = (e) => {
    this.setState({
      info: {
        ...this.state.info,
        phoneNumber: e.target.value,
      },
    });
  };
  handleChangeEmail = (e) => {
    this.setState({
      info: {
        ...this.state.info,
        email: e.target.value,
      },
    });
  };
  handleChangeMessage = (e) => {
    this.setState({
      info: {
        ...this.state.info,
        message: e.target.value,
      },
    });
  };

  handleSent = () => {
    this.setState({ sent: true });
  };

  handleSubmit = (e) => {
    this.setState({ sent: true });
    e.preventDefault();
    firebase
      .firestore()
      .collection("email_template")
      .add({
        from: "this.state.email",
        to: "rda.totallypets@gmail.com",
        message: {
          subject: "New contact from totallypets.com",
          html: `<!DOCTYPE html>
            <html lang="en">
            <head>
                <meta charset="UTF-8">
                <meta name="viewport" content="width=device-width, initial-scale=1.0">
                <title>Document</title>
            </head>
            <body>
            <h2>A new customer just sent you a message through totallypets.com!</h2>
                <h5>First Name:</h5>
                <p>${this.state.info.firstName}</p>
                <h5>Last Name:</h5>
                <p>${this.state.info.lastName}</p>
                <h5>Phone Number:</h5>
                <p>${this.state.info.phoneNumber}</p>
                <h5>Email:</h5>
                <p>${this.state.info.email}</p>
                <h5>Message:</h5>
                <p>${this.state.info.message}</p>
            </body>
            </html>`,
        },
      })
      .then(function(docRef) {
        console.log("Document written with ID: ", docRef.id);
      })
      .catch(function(error) {
        console.error("Error adding document: ", error);
      });
  };

  render() {
    let submissionArea = (
      <button id="" className="btn btn-solid" type="submit">
        Send Your Message
      </button>
    );
    if (this.state.sent) {
      submissionArea = <h4>Your message has been sent!</h4>;
    }

    return (
      <div>
        <Breadcrumb title={"Contact Us"} />

        {/*Forget Password section*/}
        <section className=" contact-page section-b-space">
          <div className="container">
            <div className="row section-b-space">
              <div className="col-lg-7 map">
                <iframe
                  src="https://www.google.com/maps/embed/v1/place?q=place_id:ChIJ25gSRRCnEmsRAeUCffYPCGY&key=AIzaSyA8q8E9bVY_rdepsQEReAniYO3qIq3SjS4"
                  allowFullScreen
                ></iframe>
              </div>
              <div className="col-lg-5">
                <div className="contact-right">
                  <ul>
                    <li>
                      <div className="contact-icon">
                        <img
                          src={`${process.env.PUBLIC_URL}/assets/images/icon/phone.png`}
                          alt="Generic placeholder image"
                        />
                        <h6>Contact Us</h6>
                      </div>
                      <div className="media-body">
                        <p>+61  02 - 9487 - 1464</p>
                        <p> </p>
                        {/* <p>+86 163 - 451 - 7894</p> */}
                      </div>
                    </li>
                    <li>
                      <div className="contact-icon">
                        <i className="fa fa-map-marker" aria-hidden="true"></i>
                        <h6>Address</h6>
                      </div>
                      <div className="media-body">
                        <p>Unit 27,35 Sefton Road,Thornleigh</p>
                        <p>NSW 2120</p>
                      </div>
                    </li>
                    <li>
                      <div className="contact-icon">
                        <img
                          src={`${process.env.PUBLIC_URL}/assets/images/icon/email.png`}
                          alt="Generic placeholder image"
                        />
                        <h6>Address</h6>
                      </div>
                      <div className="media-body">
                        {/* <p>Support@Shopcart.com</p> */}
                        <p>rad.totallypets@gmail.com</p>
                      </div>
                    </li>
                    <li>
                      <div className="contact-icon">
                        <img></img>
                        <h6>Opening Hours</h6>
                      </div>
                      <div className="media-body">
                        {/* <p>Support@Shopcart.com</p> */}
                        <p>Monday - Friday</p>
                        <p>8:30 - 16:30</p>
                      </div>
                    </li>
                    {/* <li>
                                            <div className="contact-icon">
                                                <i className="fa fa-fax" aria-hidden="true"></i>
                                                <h6>Fax</h6>
                                            </div>
                                            <div className="media-body">
                                                <p>Support@Shopcart.com</p>
                                                <p>info@shopcart.com</p>
                                            </div>
                                        </li> */}
                  </ul>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12">
                <form className="theme-form" onSubmit={this.handleSubmit}>
                  <div className="form-row">
                    <div className="col-md-6">
                      <label htmlFor="name">First Name</label>
                      <input
                        type="text"
                        className="form-control"
                        id="name"
                        placeholder="Enter your first name"
                        required
                        value={this.state.firstName}
                        onChange={this.handleChangeFirstName}
                      />
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="email">Last Name</label>
                      <input
                        type="text"
                        className="form-control"
                        id="last-name"
                        placeholder="Enter your last name"
                        required
                        value={this.state.lastName}
                        onChange={this.handleChangeLastName}
                      />
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="review">Phone number</label>
                      <input
                        type="text"
                        className="form-control"
                        id="review"
                        placeholder="Enter your number"
                        required
                        value={this.state.phoneNumber}
                        onChange={this.handleChangePhoneNumber}
                      />
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="email">Email</label>
                      <input
                        type="text"
                        className="form-control"
                        id="email"
                        placeholder="Enter your email"
                        required
                        value={this.state.email}
                        onChange={this.handleChangeEmail}
                      />
                    </div>
                    <div className="col-md-12">
                      <label htmlFor="review">Write Your Message</label>
                      <textarea
                        className="form-control"
                        placeholder="Write your message"
                        id="exampleFormControlTextarea1"
                        rows="6"
                        required
                        onChange={this.handleChangeMessage}
                        value={this.state.message}
                      ></textarea>
                    </div>
                    <div className="col-md-12">{submissionArea}</div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default Contact;
