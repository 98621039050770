import React, { Component } from 'react';
import Slider from 'react-slick';
import '../common/index.scss';
import { connect } from 'react-redux';
import firebase from 'firebase';

// import custom Components
import RelatedProduct from '../common/related-product';
import Breadcrumb from '../common/breadcrumb';
import DetailsWithPrice from './common/product/details-price';
import DetailsTopTabs from './common/details-top-tabs';
import { addToCart, addToCartUnsafe, addToWishlist } from '../../actions';
import ImageZoom from './common/product/image-zoom';
import SmallImages from './common/product/small-image';

class NoSideBar extends Component {
  constructor() {
    super();
    this.state = {
      nav1: null,
      nav2: null,

      image: `${process.env.PUBLIC_URL}/assets/images/product-placeholder.jpg`,
    };
  }

  componentDidMount() {
    this.setState({
      nav1: this.slider1,
      nav2: this.slider2,
    });

    if (firebase.apps.length === 0) {
      firebase.initializeApp({
        apiKey: 'AIzaSyA8q8E9bVY_rdepsQEReAniYO3qIq3SjS4',
        authDomain: 'totallypets-3ec88.firebaseapp.com',
        projectId: 'totallypets-3ec88',
        storageBucket: 'gs://totallypets-3ec88.appspot.com/',
      });
    }

    let storage = firebase.storage();
    let ID = this.props.item.id;
    let imageRef = storage.ref(`product-images/${ID}.jpg`);
    imageRef
      .getDownloadURL()
      .then((url) => {
        this.setState({ image: url });
      })
      .catch((error) => {
        console.log(`cannot find image with id ${ID}`);
        this.setState({
          image: `${process.env.PUBLIC_URL}/assets/images/product-placeholder.jpg`,
        });
      });
  }

  fixToFiveDigit = (ID) => {
    if (ID.length >= 5) return ID;
    while (ID.length < 5) {
      ID = '0' + ID;
    }
    return ID;
  };

  render() {
    const {
      symbol,
      item,
      addToCart,
      addToCartUnsafe,
      addToWishlist,
    } = this.props;
    var products = {
      fade: true,
    };

    var productsnav = {
      slidesToShow: 3,
      slidesToScroll: 1,
      swipeToSlide: true,
      draggable: true,
      focusOnSelect: true,
    };

    return (
      <div>
        <Breadcrumb title={' Product / ' + item.name} />

        {/*Section Start*/}
        {item ? (
          <section className='mb-5'>
            <div className='collection-wrapper'>
              <div className='container'>
                <div className='row'>
                  <div className='col-lg-6 product-thumbnail'>
                    <Slider
                      {...products}
                      asNavFor={this.state.nav2}
                      ref={(slider) => (this.slider1 = slider)}
                      className='product-slick'
                    >
                      {/* {item.variants.map((vari, index) =>
                      <div key={index}> */}
                      {/* <ImageZoom image={vari.images} className="img-fluid image_zoom_cls-0" /> */}
                      <ImageZoom
                        image={this.state.image}
                        className='img-fluid image_zoom_cls-0'
                      />
                      {/* </div>
                      )} */}
                    </Slider>
                    {/* <SmallImages
                      item={item}
                      settings={productsnav}
                      navOne={this.state.nav1}
                    /> */}
                  </div>
                  <DetailsWithPrice
                    symbol={symbol}
                    item={item}
                    navOne={this.state.nav1}
                    addToCartClicked={addToCart}
                    BuynowClicked={addToCartUnsafe}
                    addToWishlistClicked={addToWishlist}
                  />
                </div>
              </div>
            </div>
          </section>
        ) : (
          ''
        )}
        {/*Section End*/}

        {/* <section className="tab-product m-0">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12 col-lg-12">
                                <DetailsTopTabs item={item} />
                            </div>
                        </div>
                    </div>
                </section> */}

        {/* <RelatedProduct /> */}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  let productId = ownProps.match.params.id;
  return {
    item: state.data.products.find((el) => el.id == productId),
    symbol: state.data.symbol,
  };
};

export default connect(mapStateToProps, {
  addToCart,
  addToCartUnsafe,
  addToWishlist,
})(NoSideBar);
