import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { Redirect } from "react-router-dom";
import "../../common/index.scss";
import Slider from "react-slick";

// Import custom components
import Collection from "./collection";
import LogoBlock from "../common/logo-block";
import BlogSection from "../common/blogsection";
import HeaderOne from "../../common/headers/header-one";
// import HeaderTwo from "../../common/headers/header-two"
// import HeaderThree from "../../common/headers/header-three"
// import HeaderFour from "../../common/headers/header-four"
// import HeaderFive from "../../common/headers/header-five"

import FooterTwo from "../../common/footers/footer-two";
// import FooterOne from "../../common/footers/footer-one"
import ThemeSettings from "../../common/theme-settings";

class Pets extends Component {
  state = {
    toProducts: false,
    targetCategory: null,
    targetItemType: null,
  };

  componentDidMount() {
    document
      .getElementById("color")
      .setAttribute("href", `${process.env.PUBLIC_URL}/assets/css/color15.css`);
  }

  onViewButtonClicked = (targetCategory, targetItemType) => {
    // this.props.history.push('/products')
    this.setState({
      toProducts: true,
      targetCategory: targetCategory,
      targetItemType: targetItemType,
    });
  };

  render() {
    if (this.state.toProducts === true) {
      return (
        <Redirect
          to={{
            pathname: `${process.env.PUBLIC_URL}/products`,
            state: {
              category: this.state.targetCategory,
              itemType: this.state.targetItemType,
            },
          }}
        />
      );
      // return <Redirect to={`${process.env.PUBLIC_URL}/products`}/>
    }

    const bannerStyle = {
      borderRadius: "15px",
    };
    return (
      <div>
        <Helmet>
          <title>Totally Pets | Pets Store</title>
        </Helmet>
        {/* <HeaderOne logoName={'totally-pets-logo-135-70-brighten.png'}/> */}
        <section className="p-0 small-slider">
          <Slider className="slide-1 home-slider">
            <div>
              <div className="home home46">
                <div className="container">
                  <div className="row">
                    <div className="col">
                      <div className="slider-contain">
                        <div>
                          <h4>every pet is different</h4>
                          <h1>let's see</h1>
                          <a
                            onClick={() =>
                              this.onViewButtonClicked("all", "all")
                            }
                            className="btn btn-solid"
                          >
                            view
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div className="home home45">
                <div className="container">
                  <div className="row">
                    <div className="col">
                      <div className="slider-contain">
                        <div>
                          <h4>Any thing that</h4>
                          <h1>pets want</h1>
                          <a
                            onClick={() =>
                              this.onViewButtonClicked("all", "all")
                            }
                            className="btn btn-solid"
                          >
                            view
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Slider>
        </section>
        {/*Two Banner*/}
        <section className="pb-0 ratio2_1">
          <div className="container">
            <div className="row partition2">
              <div className="col-md-6">
                <a
                  href="javascript:void(0)"
                  onClick={() => this.onViewButtonClicked("dog", "all")}
                >
                  <div
                    className="collection-banner p-right text-center"
                    style={bannerStyle}
                  >
                    <div className="img-part">
                      <img
                        src={`${process.env.PUBLIC_URL}/assets/images/sub-banner1.jpg`}
                        className="img-fluid blur-up lazyload bg-img"
                        alt=""
                      />
                    </div>
                    <div className="contain-banner">
                      <div>
                        {/* <h4>save 30%</h4> */}
                        <h2>dog</h2>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
              <div className="col-md-6">
                <a
                  href="javascript:void(0)"
                  onClick={() => this.onViewButtonClicked("cat", "all")}
                >
                  <div
                    className="collection-banner p-right text-center"
                    style={bannerStyle}
                  >
                    <div className="img-part">
                      <img
                        src={`${process.env.PUBLIC_URL}/assets/images/sub-banner2.jpg`}
                        className="img-fluid blur-up lazyload bg-img"
                        alt=""
                      />
                    </div>
                    <div className="contain-banner">
                      <div>
                        {/* <h4>save 60%</h4> */}
                        <h2>cat</h2>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </section>
        {/*Two Banner End*/}
        {/*Three Banner*/}
        <section className="banner-goggles ratio2_1">
          <div className="container">
            <div className="row partition3">
              <div className="col-md-4">
                <a
                  href="javascript:void(0)"
                  onClick={() => this.onViewButtonClicked("fish", "all")}
                >
                  <div
                    className="collection-banner p-right text-right"
                    style={bannerStyle}
                  >
                    <div className="img-part">
                      <img
                        src={`${process.env.PUBLIC_URL}/assets/images/electronics/5.jpg`}
                        className="img-fluid blur-up lazyload bg-img"
                        alt=""
                      />
                    </div>
                    <div className="contain-banner banner-3">
                      <div>
                        {/* <h4>10% off</h4> */}
                        <h2>fish</h2>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
              <div className="col-md-4">
                <a
                  href="javascript:void(0)"
                  onClick={() => this.onViewButtonClicked("bird", "all")}
                >
                  <div
                    className="collection-banner p-right text-right"
                    style={bannerStyle}
                  >
                    <div className="img-part">
                      <img
                        src={`${process.env.PUBLIC_URL}/assets/images/electronics/6.jpg`}
                        className="img-fluid blur-up lazyload bg-img"
                        alt=""
                      />
                    </div>
                    <div className="contain-banner banner-3">
                      <div>
                        {/* <h4>10% off</h4> */}
                        <h2>bird</h2>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
              <div className="col-md-4">
                <a
                  href="javascript:void(0)"
                  onClick={() => this.onViewButtonClicked("other", "all")}
                >
                  <div
                    className="collection-banner p-right text-right"
                    style={bannerStyle}
                  >
                    <div className="img-part">
                      <img
                        src={`${process.env.PUBLIC_URL}/assets/images/electronics/7.jpg`}
                        className="img-fluid blur-up lazyload bg-img"
                        alt=""
                      />
                    </div>
                    <div className="contain-banner banner-3">
                      <div>
                        {/* <h4>50% off</h4> */}
                        <h2>other</h2>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </section>
        {/*Three Banner End*/}
        {/*Parallax banner*/}
        <section className="p-0 pet-parallax mt-5">
          <div className="full-banner parallax parallax-banner19  text-center p-center">
            <div className="container">
              <div className="row">
                <div className="col">
                  <div className="banner-contain">
                    <h3>About Us</h3>
                    <p>
                      We are a young and energetic supplier of pet products. We
                      not only provide high-quality products from Australia, but
                      also make global purchases. We maintain close contact with
                      the largest pet products exhibition, and we understand the
                      latest fashion trends. We are a stable supplier of
                      Australia's largest retail group. We provide quality
                      products and services to more than 100 IGA supermarkets
                      and pet supply stores. Creating more value for customers
                      is our philosophy.
                    </p>
                    <p>Please have a look at our products. </p>
                    <a
                      onClick={() => this.onViewButtonClicked("all", "all")}
                      className="btn btn-solid black-btn"
                      tabIndex="0"
                    >
                      view
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="pet-decor">
              <img
                src={`${process.env.PUBLIC_URL}/assets/images/dog.png`}
                alt=""
                className="img-fluid blur-up lazyload"
              />
            </div>
          </div>
        </section>
        {/*Parallax banner end*/}
        {/*Logo Block section*/}

        {/*TODO: add this logo block*/}
        {/* <LogoBlock /> */}

        {/*Logo Block section end*/}
        {/*Product Section*/}
        {/* <Collection type={'pets'} title="TOP PRODUCTS" subtitle="On Stock"/> */}
        {/*Product Section End*/}
        {/*Product Slider*/}
        {/* <Collection type={'pets'} title="SAVE AND EXTRA" /> */}
        {/*Product Slider End*/}
        {/* Blog Section Section*/}
        {/* <div className="container ">
                    <div className="row">
                        <div className="col">
                            <div className="title1 title5">
                                <h4>Recent Story</h4>
                                <h2 className="title-inner1">from the blog</h2>
                                <hr role="tournament6" />
                            </div>
                        </div>
                    </div>
                </div>
                <section className="section-b-space p-t-0 ratio2_3">
                    <BlogSection />
                </section> */}
        {/* Blog Section End*/}
        {/* <ThemeSettings/> */}
        {/* <FooterTwo logoName={'totally-pets-logo-135-70-brighten.png'}/> */}
      </div>
    );
  }
}

export default Pets;
