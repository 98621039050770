/**
 * Mocking client-server processing
 */
import firebase from 'firebase';
import _products from './data.json';

const TIMEOUT = 100;

export default {
  // getProducts: (cb, timeout) => setTimeout(() => cb(_products), timeout || TIMEOUT),
  getProducts: (cb, timeout) => {
    let products = null;
    let productsRef = firebase.database().ref('Active/');
    productsRef.on('value', (snapshot) => {
      const data = snapshot.val();
      //   console.log(data);
      cb(data);
    });
    // console.log(_products);
    // setTimeout(() => cb(_products), timeout || TIMEOUT);
  },
  buyProducts: (payload, cb, timeout) =>
    setTimeout(() => cb(), timeout || TIMEOUT),
};
