import React, { Component } from "react";
import Slider from "react-slick";
import { connect } from "react-redux";

import firebase from "firebase";
import "../common/index.scss";

// import custom Components
import ProductListing from "./common/product-listing";
import Breadcrumb from "../common/breadcrumb";
import FilterBar from "./common/filter-bar";
import { getVisibleproducts } from "../../services";
import { filterCategory, filterItemType } from "../../actions";

class CollectionNoSideBar extends Component {
  constructor() {
    super();
    if (firebase.apps.length === 0) {
      firebase.initializeApp({
        apiKey: "AIzaSyA8q8E9bVY_rdepsQEReAniYO3qIq3SjS4",
        authDomain: "totallypets-3ec88.firebaseapp.com",
        projectId: "totallypets-3ec88",
        storageBucket: "gs://totallypets-3ec88.appspot.com/",
      });
    }
  }

  state = {
    layoutColumns: 3,
    category: null,
    itemType: null,
  };

  LayoutViewClicked(colums) {
    this.setState({
      layoutColumns: colums,
    });
  }

  componentDidUpdate() {
    if (this.props.location.state === undefined) return;
    if (
      this.state.category !== this.props.location.state.category ||
      this.state.itemType !== this.props.location.state.itemType
    ) {
      this.setState({
        category: this.props.location.state.category,
        itemType: this.props.location.state.itemType,
      });
      this.updateVisibleProducts(
        this.props.location.state.category,
        this.props.location.state.itemType
      );
    }
  }

  componentDidMount() {
    if (this.props.location.state === undefined) {
      this.props.history.push("/");
    } else {
      this.setState({
        category: this.props.location.state.category,
        itemType: this.props.location.state.itemType,
      });
      this.updateVisibleProducts(
        this.props.location.state.category,
        this.props.location.state.itemType
      );
    }
  }

  updateVisibleProducts = (category, itemType) => {
    // console.log("updateVisibleProducts")
    // console.log(category);
    // console.log(itemType);

    this.props.filterCategory(this.translateName(category));
    this.props.filterItemType(this.translateName(itemType));
  };

  translateName = (uiName) => {
    switch (uiName) {
      case "other":
        return "OTHER";
      case "bird":
        return "BIRD";
      case "dog":
        return "DOG";
      case "cat":
        return "CAT";
      case "fish":
        return "FISH";
      case "food":
        return "FOOD";
      case "treat":
        return "TREAT";
      case "toy":
        return "TOY";
      case "food_and_supplements":
        return "FOOD & SUPPLEMENTS";
      case "grooming_and_cleaning":
        return "Grooming & Cleaning";
      case "health_and_pharmaceuticals":
        return "Health & Pharmaceuticals";
      case "feeders_and_drinkers":
        return "FEEDERS & DRINKERS";
      case "litter_or_trays":
        return "LITTER/ TRAYS";
      case "medications_and_treatments":
        return "MEDICATIONS & TREATMENTS";
      case "gravel":
        return "GRAVEL";
      case "accessories":
        return "ACCESSORIES";
      case "nest_box":
        return "NEST BOX";
      case "collars_and_leads":
        return "COLLARS & LEADS";
      case "bedding":
        return "BEDDING";
      case "bottle":
        return "BOTTLE";
      case "clean_up":
        return "CLEAN UP";
      case "all":
        return "ALL";
      default:
        return null;
    }
  };

  render() {
    return (
      <div>
        <Breadcrumb
          parent={"Products"}
          title={this.state.category + " : " + this.state.itemType}
        />

        {/*Section Start*/}
        <section className="section-b-space">
          <div className="collection-wrapper">
            <div className="container">
              <div className="row">
                <div className="collection-content col">
                  <div className="page-main-content">
                    <div className="container-fluid">
                      <div className="row">
                        <div className="col-sm-12">
                          <div className="top-banner-wrapper">
                            <a href="javascript:void(0)">
                              <img
                                src={`${process.env.PUBLIC_URL}/assets/images/mega-menu/2.jpg`}
                                className="img-fluid"
                                alt=""
                              />
                            </a>
                            <div className="top-banner-content small-section">
                              <h4>Our Products:</h4>
                              <h5>
                                We are a young and energetic supplier of pet
                                products. We not only provide high-quality
                                products from Australia, but also make global
                                purchases. We maintain close contact with the
                                largest pet products exhibition, and we
                                understand the latest fashion trends. We are a
                                stable supplier of Australia's largest retail
                                group. We provide quality products and services
                                to more than 100 IGA supermarkets and pet supply
                                stores. Creating more value for customers is our
                                philosophy.
                              </h5>
                              <p> </p>
                            </div>
                          </div>
                          <div className="collection-product-wrapper">
                            <div className="product-top-filter">
                              <div className="container-fluid p-0">
                                <div className="row">
                                  <div className="col-12">
                                    <FilterBar
                                      onLayoutViewClicked={(colmuns) =>
                                        this.LayoutViewClicked(colmuns)
                                      }
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="product-wrapper-grid">
                              <div className="container-fluid">
                                <div className="row">
                                  <ProductListing
                                    colSize={this.state.layoutColumns}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*Section End*/}
      </div>
    );
  }
}

export default connect(
  // mapStateToProps,
  null,
  { filterCategory, filterItemType }
)(CollectionNoSideBar);
