import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { ScrollContext } from 'react-router-scroll-4';
import { IntlReducer as Intl, IntlProvider } from 'react-redux-multilingual';
import firebase from 'firebase';
import firestore from 'firebase/firestore';

import './index.scss';

// Import custom components
import store from './store';
import translations from './constants/translations';
import { getAllProducts } from './actions';
import Landing from './components/landing';

// Layouts

import Pets from './components/layouts/pets/main';

//Collection Pages
import CollectionNoSidebar from './components/collection/collection-no-sidebar';
// Product Pages
import LeftSideBar from './components/products/left-sidebar';
import RightSideBar from './components/products/right-sidebar';
import NoSideBar from './components/products/no-sidebar';
import LeftImage from './components/products/left-image';
import RightImage from './components/products/right-image';
import Accordian from './components/products/accordian';
import ColumnLeft from './components/products/column-left';
import ColumnRight from './components/products/column-right';
import Column from './components/products/column';
import Vertical from './components/products/vertical';

// Features
import Layout from './components/app';
import Cart from './components/cart';
import Compare from './components/compare/index';
import wishList from './components/wishlist';
import checkOut from './components/checkout';
import orderSuccess from './components/checkout/success-page';

// Extra Pages
import aboutUs from './components/pages/about-us';
import PageNotFound from './components/pages/404';
import lookbook from './components/pages/lookbook';
import Login from './components/pages/login';
import Register from './components/pages/register';
import Search from './components/pages/search';
import Collection from './components/pages/collection';
import ForgetPassword from './components/pages/forget-password';
import Contact from './components/pages/contact';
import Dashboard from './components/pages/dashboard';
import Faq from './components/pages/faq';

// Blog Pages
import RightSide from './components/blogs/right-sidebar';
import Details from './components/blogs/details';
import BlogPage from './components/blogs/blog-page';

// Theme Element
import ElementTitle from './components/features/theme/element-title';
import ElementBanner from './components/features/theme/element-banner';
import ElementSlider from './components/features/theme/element-slider';
import ElementCategory from './components/features/theme/element-category';
import ElementService from './components/features/theme/element-service';
import ElementRatio from './components/features/theme/element-ratio';

// Product Elements
import ElementProductBox from './components/features/product/element-product-box';
import ElementProductSlider from './components/features/product/element-product-slider';
import ElementProductNoSlider from './components/features/product/element-product-no-slider';
import ElementMultipleSlider from './components/features/product/element-multiple-slider';
import ElementProductTab from './components/features/product/element-product-tab';

// Portfolio Features
import GridCols from './components/features/portfolio/grid-cols';
import MasonaryGridCols from './components/features/portfolio/masonary-grid-cols';

class Root extends React.Component {
  componentDidMount() {
    if (firebase.apps.length === 0) {
      firebase.initializeApp({
        apiKey: 'AIzaSyA8q8E9bVY_rdepsQEReAniYO3qIq3SjS4',
        authDomain: 'totallypets-3ec88.firebaseapp.com',
        projectId: 'totallypets-3ec88',
        storageBucket: 'gs://totallypets-3ec88.appspot.com/',
      });
    }
    let storage = firebase.storage();
    store.dispatch(getAllProducts());

    // console.log("8:44 may/12");
  }

  render() {
    return (
      <Provider store={store}>
        <IntlProvider translations={translations} locale='en'>
          <BrowserRouter basename={'/'}>
            <ScrollContext>
              <React.Fragment>
                <div className='loader-wrapper'>
                  <div className='loader'></div>
                </div>
                <Layout>
                  {/*Routes For Layouts*/}
                  <Switch>
                    <Route
                      path={`${process.env.PUBLIC_URL}/no-sidebar/product/:id`}
                      component={NoSideBar}
                    />
                    <Route
                      path={`${process.env.PUBLIC_URL}/products`}
                      component={CollectionNoSidebar}
                    />
                    <Route
                      path={`${process.env.PUBLIC_URL}/contact-us`}
                      component={Contact}
                    />
                    <Route
                      exact
                      path={`${process.env.PUBLIC_URL}/`}
                      component={Pets}
                    />
                    <Route component={PageNotFound} />
                  </Switch>
                  {/* <Route exact path="*" component={PageNotFound} /> */}
                </Layout>
              </React.Fragment>
            </ScrollContext>
          </BrowserRouter>
        </IntlProvider>
      </Provider>
    );
  }
}

ReactDOM.render(<Root />, document.getElementById('root'));
